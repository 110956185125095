import React, {useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import ReactLoading from "react-loading";
import styled from "styled-components";

import { getTeachers } from "../helpers/utils";


const Loading = styled('div')`
  margin: 0;
   position: absolute;               /* 2 */
   top: 50%; 
   left: 50%;                       /* 3 */
   transform: translate(0, -50%) }   /* 4 */`;

function Teachers() {
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    if (teachers.length == 0) {
      setLoading(true);
      const res = Object.values(await getTeachers('teachers'));
      const splitted = [];
      const sorted = res.sort(function(a, b) {return a.id - b.id});
      for (let i = 0; i < res.length; i += 3) {
          const chunk = res.slice(i, i + 3);
          splitted.push(chunk);
      }
      setTeachers(splitted);
      setLoading(false);
    }

  }, []);

  if (loading){
    return (
      <Loading>
        <ReactLoading type='bars' color="#a273ff"/>
      </Loading>
    )
  };

  return (
    <AnimationRevealPage>
      <Header />
      <Testimonial
        heading="Our Wonderful Teachers"
        testimonials = {teachers}
        description="Our team of educators originate from across the United States with profound education background, certified capabilities and years of experience. We offer the most professional skills, the most creative learning environment and a variety of class materials for your child to prosper on this long road of knowledge seeking!"
      />
      <Footer/>
    </AnimationRevealPage>
  );
}

export default Teachers;
