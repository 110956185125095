import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, query, getDocs } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAx2jJG17-hZq8mY4ClHyLULGsM3bf9YTA",
    authDomain: "lead-win-web.firebaseapp.com",
    databaseURL: "https://lead-win-web-default-rtdb.firebaseio.com",
    projectId: "lead-win-web",
    storageBucket: "lead-win-web.appspot.com",
    messagingSenderId: "209048618656",
    appId: "1:209048618656:web:ac48ea83ebd19511f6abc5",
    measurementId: "G-2YGDKHS0NW"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);


export async function getTeachers(docName) {
    const q = query(collection(db, docName));
    const querySnapshot = await getDocs(q);
    const res = {}
    querySnapshot.forEach((doc) => {
        const name = doc.data()['name'];
        res[name] = doc.data();
    });

    const storage = getStorage(app);

    for (const teacher of Object.keys(res)){
        const url = await getDownloadURL(ref(storage, `teacherImages/${res[teacher]['imgName']}`)).catch((err) => (console.log('error')));
        res[teacher]['imgUrl'] = url;
    }

    return res;
}

